import { createStore } from "vuex";

export default createStore({
  //数据存储属性
  state: {
    num: 0,
    token: window.sessionStorage.getItem("token"),
    userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
    keyword: "",
    type: "",
    unread_num:0,
    menu:JSON.parse(window.sessionStorage.getItem("menu")) || [],
    menu_tree:JSON.parse(window.sessionStorage.getItem("menu_tree")) || [],
    isKeyDownListenerAdded: 0,
  },
  //方法属性
  mutations: {
    ADD_KEY_DOWN_LISTENER(state) {  
      state.isKeyDownListenerAdded += 1; 
    },  
    REMOVE_KEY_DOWN_LISTENER(state) {  
      state.isKeyDownListenerAdded = false;  
    },  
    unread_msg: (state, data) => {
      state.unread_num = data;
    },
    //将token保存到sessionStorage里，token表示登陆状态
    SET_TOKEN: (state, data) => {
      state.token = data;
      window.sessionStorage.setItem("token", data);
    },
    SET_MENU: (state, data) => {
      state.menu = data;
      window.sessionStorage.setItem("menu", JSON.stringify(data));
    },
    SET_MENU_TREE: (state, data) => {
      state.menu_tree = data;
      window.sessionStorage.setItem("menu_tree", JSON.stringify(data));
    },
    SET_KEYWORD: (state, data) => {
      state.keyword = data;
    },
    SET_TYPE: (state, data) => {
      state.type = data;
    },
    //获取用户信息
    GET_USER: (state, data) => {
      // 把用户名存起来
      state.userInfo = data;
      window.sessionStorage.setItem("userInfo", JSON.stringify(data));
    },
    LOGOUT: (state) => {
      // 登出的时候要清除token
      state.token = null;
      state.userInfo = null;
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("menu");
      window.sessionStorage.removeItem("menu_tree");
      window.sessionStorage.removeItem("userInfo");
    },
  },
  //异步属性
  actions: {
    ACTaddnum: function({ commit }, params) {
      setTimeout(() => {
        commit("MUTaddNum", params);
      }, 1000);
    },
    addKeyDownListener({ commit }) {   
      commit('ADD_KEY_DOWN_LISTENER'); 
    },  
    removeKeyDownListener({ commit }) {  
      commit('REMOVE_KEY_DOWN_LISTENER');  
    }, 
  },
  //计算属性
  getters: {
    token: (state) => state.token,
    handleKeyDown: state => state.handleKeyDown || this.methods.handleKeyDown // 确保可以正确获取 handleKeyDown 方法
  },
  modules: {},
});
