
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import "./assets/css/common.css";
import "./assets/css/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import pagination from "./components/pagination.vue";
import selectProducts from "./components/selectProducts.vue";
import mitt from "mitt";
import { httpGet, httpPost } from "@/apis/api";
const app = createApp(App);
app.config.globalProperties.$httpGet=httpGet;
app.config.globalProperties.$httpPost=httpPost;

if (process.env.NODE_ENV === "development") {
  app.config.globalProperties.$http="https://dev.cnsunrun.com:8134";  //主要是图片上传
} else if (process.env.NODE_ENV === "production") {
  app.config.globalProperties.$http="";  //主要是图片上传
}
const whiteList = ["/login", "/index"];

app.config.globalProperties.have_auth=function(url){
    let n=0;
    for(let i=0;i<store.state.menu.length;i++){
        if(url==store.state.menu[i].url){
            n++
            break;
        }
    }
    return n>0?true:false
}
app.config.globalProperties.today_val=function(){
    return app.config.globalProperties.getMyDate(Date.parse(new Date()),'year')
}
app.config.globalProperties.getMyDate=function(str, _type) {
    var oDate = new Date(str),
        oYear = oDate.getFullYear(),
        oMonth = oDate.getMonth() + 1,
        oDay = oDate.getDate(),
        oHour = oDate.getHours(),
        oMin = oDate.getMinutes(),
        oSen = oDate.getSeconds();
    //  格式 13:12
    if (_type == 'time_h_m') {
        var oTime = getzf(oHour) + ':' + getzf(oMin)
    }
    if (_type == 'time_h_ms') {
        
        var _times = getzf(oMin).toString()[0] + '0'
        var oTime = getzf(oHour) + ':' + _times
    }
    // 格式 13:12:25
    if (_type == 'time_h_m_s') {
        var oTime = getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);
    }
    // 格式 2017-12-12
    if (_type == 'year') {
        var oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay)
    }
    // 格式 2017-12
    if (_type == 'year_m') {
        var oTime = oYear + '-' + getzf(oMonth)
    }
    // 格式 2017-12-12 13:12:25
    if (_type == 'year_time') {
        var oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);//最后拼接时间  
    }
    // 需要更多格式可再自定义
    return oTime;
        
    //补0操作
    function getzf(num) {
        if (parseInt(num) < 10) {
            num = '0' + num;
        }
        return num;
    }
}
const _this=app.config.globalProperties;
// 注册全局钩子用来拦截导航
router.beforeEach((to, from, next) => {

  // NProgress.start();
  if (store.getters.token) {
    // 判断是否有token
    if (to.path === "/login") {
      next({
        path: store.state.menu_tree[0].url,
      });
    } else {
        // if(app.config.globalProperties.have_auth(to.path) || to.path=="/notFound" || whiteList.indexOf(to.path) !== -1){
            // next();
        // }else{
        //     next('/notFound');
        // }
        
        if((from.path=='/addpurchasePlan' || from.path=='/addPlan'|| from.path =='/addSheet') && !window.is_need_back){
            // return false;
            _this.$confirm('是否确认退出编辑页面?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function() {
                next();
            }).catch((err) => {
                return false
            });
        }else{
            window.is_need_back=false;
            next();
        }
        
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next(); 
    } else {
      next("/login"); // 否则全部重定向到登录页
    }
  }
});
app.config.globalProperties.$mitt = mitt();
app
  .use(ElementPlus, { locale })
  .use(router)
  .use(store);
app.component("pagination",pagination);
app.component("selectProducts",selectProducts);
app.mount("#app");
