<template>
  <div :class="{ 'hidden': hidden }" class="pagination-container">
    <div style="display: flex; " class="soloPage">
      <div style="margin-right: 16px">
        <span style="font-size: 14px"> 共{{ total }}条</span>
        <el-select size="small" style="width: 85px; margin: 0 6px" v-model="pageSize" filterable allow-create
          default-first-option>
          <el-option v-for="(item, index) of options" :key="index" :value="Number(item.value)" :label="item.label"></el-option>
        </el-select>
        <span style="font-size: 14px">条/页</span>
      </div>
    </div>
    <el-pagination :background="background" v-model:current-page="currentPage" v-model:page-size="pageSize"
      :layout="layout" :page-sizes="pageSizes" :total="total" v-bind="$attrs" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10,50,100,500,1000]
      }
    },
    layout: {
      type: String,
      default: 'prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    customPageSize: {
      type: Number,
      default: 5000
    }
  },
  data(){
    return {
      options: [
        {
          value: 10,
          label: 10,
        },
        {
          value: 50,
          label: 50,
        },
        {
          value: 100,
          label: 100,
        },
        {
          value: 500,
          label: 500,
        },
        {
          value: 1000,
          label: 1000,
        },
      ],
      pageShow:true,
    }
  },
  watch:{
    pageSize(newval,oldval){
      this.handleSizeChange(newval)
      if(this.pageSizes.indexOf(newval) == -1){
        this.pageSizes.unshift(Number(newval))
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', Number(val))
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.pagination-container {
  background: #fff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.pagination-container.hidden {
  display: none;
}
.soloPage{
  /deep/.el-input__inner{
    height: 30px;
    line-height: 30px;
  }
}
</style>
